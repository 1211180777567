<template>
  <v-col cols="12" md="6" lg="6" offset-lg="3" offset-md="3">
    <v-card class="elevation-1">
      <v-card-title>Special Computation</v-card-title>
      <v-card-text>
        <v-form>

          <!-- From Date -->
          <v-dialog
            ref="dialog"
            v-model="modalFromDate"
            :return-value.sync="fromDateValue"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="fromDate"
                label="From Date"
                outlined
                dense
                placeholder="From Date"
                v-bind="attrs"
                v-on="on"
                :append-icon="icon.calendar"
                :class="formErrorFields.fromDateErrorMessage.length > 0 ? 'mb-3' : ''"
                :error-messages="formErrorFields.fromDateErrorMessage"
                @click="formError().remove('fromDate', formErrorFields)"
              ></v-text-field>
            </template>
            <v-date-picker v-model="fromDate" scrollable>
              <v-btn text color="primary" @click="modalFromDate=false">
                Cancel
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="modalFromDate=false">
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog>

          <!-- To Date -->
          <v-dialog
            ref="dialog"
            v-model="modalToDate"
            :return-value.sync="toDateValue"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="toDate"
                label="To Date"
                outlined
                dense
                placeholder="To Date"
                v-bind="attrs"
                v-on="on"
                :append-icon="icon.calendar"
                :class="formErrorFields.toDateErrorMessage.length > 0 ? 'mb-3' : ''"
                :error-messages="formErrorFields.toDateErrorMessage"
                @click="formError().remove('toDate', formErrorFields)"
              ></v-text-field>
            </template>
            <v-date-picker v-model="toDate" scrollable>
              <v-btn text color="primary" @click="modalToDate=false">
                Cancel
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="modalToDate=false">
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog>

          <v-col cols="12">
            <v-row>
              <v-btn
                color="primary"
                :loading="loading"
                @click="showConfirmDialog=true"
                :disabled="fromDate==='' || toDate===''"
              >
                Process
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                v-if="hasPreviousProcessedData"
                color="error"
                :disabled="loading"
                @click="showConfirmUndoProcessDialog=true">
                Undo last processed
              </v-btn>
            </v-row>
          </v-col>
        </v-form>
      </v-card-text>
    </v-card>

    <snackbar
      v-if="message.length > 0"
      :show="message.length > 0"
      :text="message"
      :color="messageStatus"
      @close="message=''"
    ></snackbar>

    <confirm-password
      :show="showConfirmDialog"
      @cancel="showConfirmDialog=false"
      @execute="submit">
    </confirm-password>

    <confirm-password
      :show="showConfirmUndoProcessDialog"
      @cancel="showConfirmUndoProcessDialog=false"
      @execute="undoProcess">
    </confirm-password>
  </v-col>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import { mdiCalendarOutline } from '@mdi/js'
import crud from '@/composables/crud'
import snackbar from '@/components/SnackBar.vue'
import ConfirmPassword from '@/components/ConfirmPassword.vue'
import formError from '@/composables/formErrorHandler/formError'
import fn from '@/functions/fn'
import get from '@/composables/get'

export default {
  metaInfo: {
    title: 'Special Computation',
  },
  components: {
    snackbar,
    ConfirmPassword,
  },
  setup() {
    const loading = ref(false)
    const message = ref('')
    const messageStatus = ref('')
    const showConfirmDialog = ref(false)
    const showConfirmUndoProcessDialog = ref(false)
    const fromDateValue = ref(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000).toISOString().substr(0, 10))
    const toDateValue = ref(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000).toISOString().substr(0, 10))
    const fromDate = ref('')
    const toDate = ref('')
    const modalFromDate = ref(false)
    const modalToDate = ref(false)
    const formErrorFields = ref({
      fromDateErrorMessage: '',
      toDateErrorMessage: '',
    })
    const icon = {
      calendar: mdiCalendarOutline,
    }
    const hasPreviousProcessedData = ref(false)

    const {
      store,
      responseMessage,
      responseMessageStatus,
    } = crud()

    const checkIfThereIsData = async () => {
      await get(`${process.env.VUE_APP_URI}/api/payroll/computation/special/${fn.base64_encode({ fromDate: fromDate.value, toDate: toDate.value })}/check-data`,
        hasPreviousProcessedData,
        loading)
    }

    watch(() => fromDate.value, async val => {
      hasPreviousProcessedData.value = false
      if (toDate.value !== '' && val !== '') {
        await checkIfThereIsData()
      }
    })

    watch(() => toDate.value, async val => {
      hasPreviousProcessedData.value = false
      if (fromDate.value !== '' && val !== '') {
        await checkIfThereIsData()
      }
    })

    const undoProcess = async () => {
      showConfirmUndoProcessDialog.value = false
      await store(
        `${process.env.VUE_APP_URI}/api/payroll/computation/undo-processed-special-payroll`,
        loading,
        {
          fromDate: fromDate.value,
          toDate: toDate.value,
        },
        null,
      )
      message.value = responseMessage.value
      messageStatus.value = responseMessageStatus.value

      if (responseMessageStatus.value === 'success') {
        hasPreviousProcessedData.value = false
      }
    }

    const submit = async () => {
      showConfirmDialog.value = false
      showConfirmUndoProcessDialog.value = false
      await store(
        `${process.env.VUE_APP_URI}/api/payroll/computation/process-special`,
        loading,
        {
          fromDate: fromDate.value,
          toDate: toDate.value,
        },
        formErrorFields,
      )
      message.value = responseMessage.value
      messageStatus.value = responseMessageStatus.value
    }

    return {
      icon,
      store,
      loading,
      message,
      messageStatus,
      showConfirmDialog,
      showConfirmUndoProcessDialog,
      fromDateValue,
      fromDate,
      modalFromDate,
      toDateValue,
      toDate,
      modalToDate,
      formErrorFields,
      formError,
      hasPreviousProcessedData,

      undoProcess,
      submit,
    }
  },
}
</script>
